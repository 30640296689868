import axios from 'axios'

const myAxios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}`,

  withCredentials: true,
  //withXSRFToken: true,

  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

// myAxios.interceptors.response.use(
//     response => response,
//     error => {
//         if (error.response?.status === 401) {
//             router.push({ name: 'home' })
//         }
//         return Promise.reject(error)
//     }
// )

export { myAxios }
