import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { postLogin, postLogout } from '@/services/userService'
import type { LoginResponse } from '@/types/user'
import router from '@/router'

export const useUserStore = defineStore(
  'user',
  () => {
    const username = ref('')
    const token = ref('')

    const login = async (user: string, password: string) => {
      try {
        const response = await postLogin<LoginResponse>(user, password)
        username.value = response.data.username
        token.value = response.data.token
        console.log('Login successful')
      } catch (error: any) {
        console.error(error.response.data.message)
        throw error
      }
    }

    const logout = async () => {
      try {
        await postLogout<LoginResponse>(token.value)
        username.value = ''
        token.value = ''
        console.log('Logout successful')
        await router.push('/admin')
      } catch (error: any) {
        console.error(error.response.data.message)
        throw error
      }
    }

    const isAuthenticated = computed(() => !!username.value)

    return {
      username,
      token,
      login,
      isAuthenticated,
      logout
    }
  },
  {
    persist: true
  }
)
